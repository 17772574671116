<script>
import AdoptionsService from "@/services/adoptions.service";
import ClientsService from "@/services/clients.service";

export default {
  name: "MoveBackDialog",
  emits: ['update:visible', 'success'],
  inject: ['statuses'],
  props:{
    visible: {
      type: Boolean,
      required: true,
    },
    clients: {
      type: Array,
      required: true,
    },
    status: {
      type: Number,
      required: true,
    },
    statusName: {
      type: String,
      required: true,
    },
    initialPayload: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    pseudoStatusToStatusId: {
      1: 1,
      3: 2,
      4: 2,
      5: 5,
      6: 6,
      7: 6,
      8: 6,
      9: 6,
      10: 6,
      11: 11,
      12: 12,
      13: 13,
      14: 14,
      15: 15,
      16: 15,
      17: 15,
      18: 15,
      19: 15,
      20: 15,
      21: 15,
      22: 15,
    },
    pseudoStatusToQuarterStatus: {
      16: 1,
      17: 2,
      18: 3,
      19: 5,
    },
    currentStatus: {},
    quarterStatuses: [
      {
        value: 1,
        text: 'IRS Processing Claim'
      },
      {
        value: 2,
        text: 'Quarter Re-submission Required'
      },
      {
        value: 3,
        text: 'Quarter Denied / Appealed'
      },
      {
        value: 4,
        text: 'N/A'
      },
      {
        value: 5,
        text: 'IRS Released Payment'
      }
    ],
    quartersLoading: false,
    quarters: [],
    pseudoStatusesToExclude: [7, 8, 9, 10, 15, 19, 20, 21, 22],
    quartersToMove: [],
    loading: false,
    moveBackTo: undefined,
    payload: {
      email_sent: undefined,
      letter_stage: undefined,
      delete_quarters: undefined, // list of quarters IDs to be deleted. Optional on rollback through status 13 - Filed With IRS
      remove_quarters_status: undefined, // list of quarters IDs to set status to undefined. Switching up to this status creates quarters with status null which means rollback should be able to set it back to null. Optional through status 14 - Collecting
      update_quarters_status: undefined // required on rollback through status 15 - Transcript Verified. In practise this is last Adoption Queue Status. All statuses after are based on Quarters statuses.
    },
  }),
  methods: {
    isRollingBackToOrBefore(status) {
      return this.pseudoStatusToStatusId[this.moveBackTo] <= status && this.status > status
    },
    isRollingBackBefore(status) {
      return this.pseudoStatusToStatusId[this.moveBackTo] < status && this.status > status
    },
    getStatusColor(statusValue) {
      const statusColors = {
        1: 'yellow',      // Awaiting IRS Payments
        2: 'orange',      // Refile Needed
        3: 'red',         // Denial Received
        4: 'gray',        // N/A
        5: 'green'        // Payment Received
      };

      return statusColors[statusValue] || 'default';
    },
    getCurrentStatusName(id){
      return this.quarterStatuses.find(status => status.value === id)?.text ?? 'NOT SET'
    },
    async handleSubmit() {
      this.loading = true

      if (this.quarters.length) {
        this.payload.delete_quarters = this.quarters.filter(q => q.action === -1).map(q => q.quarter_id)
        this.payload.remove_quarters_status = this.quarters.filter(q => q.action === 0).map(q => q.quarter_id)
      }

      if (this.isChangingStatusOnly) {
        this.payload.update_quarters_status = []

        for (let quarterId of this.quartersToMove) {
          this.payload.update_quarters_status.push({
            id: quarterId,
            status: this.pseudoStatusToQuarterStatus[this.moveBackTo]
          })
        }
      }

      if (this.payload.delete_quarters && !this.payload.delete_quarters.length)
        this.payload.delete_quarters = undefined

      if (this.payload.remove_quarters_status && !this.payload.remove_quarters_status.length)
        this.payload.remove_quarters_status = undefined

      try {
        for (let client of this.clients) {
          await AdoptionsService.rollbackAdoption(client.adoption_id, this.pseudoStatusToStatusId[this.moveBackTo], this.payload)
        }
        this.$emit('success')
      } finally {
        this.loading = false
        this.$emit('update:visible', false)
      }
    },
    async getClientQuarters() {
      if (this.quarters.length) return
      this.quartersLoading = true

      try {
        this.quarters = await ClientsService.getAdoptionQuarters(this.clients[0].client_id ?? this.clients[0].id)
      } finally {
        this.quartersLoading = false
      }
    }
  },
  computed: {
    emailSentRequired() {
      return this.isRollingBackToOrBefore(2) && this.payload.letter_stage === undefined
    },
    letterStageRequired() {
      return this.isRollingBackToOrBefore(2) && this.payload.email_sent === undefined
    },
    isSubmitEnabled() {
      let introductionFlag = true
      let quarterFlag = true

      if (this.emailSentRequired && this.letterStageRequired) {
        introductionFlag = this.payload.email_sent !== undefined && this.payload.letter_stage !== undefined
      } else if (this.emailSentRequired) {
        introductionFlag = this.payload.email_sent !== undefined
      } else if (this.letterStageRequired) {
        introductionFlag = this.payload.letter_stage !== undefined
      } else {
        introductionFlag = true
      }

      if (this.isChangingStatusOnly) {
        quarterFlag = this.quartersToMove.length
      }

      return introductionFlag && quarterFlag
    },
    previousStatuses: function() {
      return this.statuses.filter(status => status.status < this.status)
        .filter(status => !this.pseudoStatusesToExclude.includes(status.status))
    },
    quartersSectionVisible() {
      return this.isRollingBackToOrBefore(13) ||
            this.isRollingBackToOrBefore(14) ||
            this.isRollingBackBefore(15)
    },
    quarterActions() {
      let actions = [];

      if (this.isRollingBackToOrBefore(13)) {
        actions.push({ name: 'Delete', value: -1 });
      }

      if (this.isRollingBackToOrBefore(14)) {
        actions.push({ name: 'Reset', value: 0 });
      }
      // if (this.isRollingBackBefore(15)) {
      //   actions.push({ name: 'Change status', value: 1 });
      // }

      return actions;
    },
    isChangingStatusOnly() {
      return this.pseudoStatusToStatusId[this.moveBackTo] === 15 && this.pseudoStatusToStatusId[this.status] === 15
    }
  },
  watch: {
    quartersSectionVisible(val) {
      if (val) {
        this.getClientQuarters()
      }
    },
    isChangingStatusOnly(val) {
      if (val) {
        this.getClientQuarters()
      }
    }
  },
  mounted() {
    if (this.initialPayload)
      this.payload = { ...this.payload, ...this.initialPayload }

    this.currentStatus = this.statuses.find(status => status.status === this.status)
  }

};
</script>

<template>
  <v-dialog
    @click:outside="$emit('update:visible', false)"
    :value="visible"
    max-width="900px"
  >
    <v-card>
      <v-card-title>
        <p>Move Back</p>
      </v-card-title>
      <v-card-subtitle>
        <p>{{ clients.length }} client<span v-if="clients.length > 1">s</span> will be moved back in workflow.</p>
      </v-card-subtitle>
      <v-card-text class="text-body-1 font-weight-regular text--primary">
        <v-row align="center" justify="space-between">
          <v-col cols="4">
            <div>Current Status</div>
          </v-col>
          <v-col cols="full">
            <v-text-field dense outlined hide-details disabled :value="statusName" />
          </v-col>
        </v-row>
        <v-row align="center" justify="space-between">
          <v-col cols="4">
            <div>Move Back To</div>
          </v-col>
          <v-col cols="full">
            <v-select
              dense
              outlined
              hide-details
              :items="previousStatuses"
              v-model="moveBackTo"
              item-text="name"
              item-value="status"
            />
          </v-col>
        </v-row>
        <div class="mt-6">
          <div
            v-if="
              (emailSentRequired || letterStageRequired) ||
              (payload.email_sent !== undefined && payload.letter_stage !== undefined)"
            style="border: 1px solid gray; border-radius: 10px" class="pa-4">
            <v-row dense>
              <v-col cols="12">
                <v-alert
                  dense
                  type="warning"
                >
                  Reverting to or before status <span class="font-weight-bold">Introduction</span> requires extra fields.
                </v-alert>
              </v-col>
            </v-row>
            <v-row dense v-if="moveBackTo === 4 || moveBackTo < 3" align="center">
              <v-col cols="4">
                Email sent
              </v-col>
              <v-col cols="full">
                <v-select
                  dense
                  outlined
                  hide-details
                  v-model="payload.email_sent"
                  :items="[
                    { name: 'Yes', value: 1 },
                    { name: 'No', value: 0 }
                  ]"
                  item-text="name"
                  item-value="value"
                />
              </v-col>
            </v-row>
            <v-row dense v-if="moveBackTo === 3 || moveBackTo < 3" align="center">
              <v-col cols="4">
                Letter stage
              </v-col>
              <v-col cols="full">
                <v-select
                  dense
                  outlined
                  hide-details
                  v-model="payload.letter_stage"
                  :items="[
                    { name: 'New Category', value: 0 },
                    { name: 'Printed Category', value: 1 }
                  ]"
                  item-text="name"
                  item-value="value"
                />
              </v-col>
            </v-row>
          </div>
          <div
            v-else-if="quartersSectionVisible" style="border: 1px solid gray; border-radius: 10px" class="pa-4 mt-2"
          >
            <v-row dense>
              <v-col cols="12">
                <p class="text-body-2">Select what to happen with each quarter (optional)</p>
              </v-col>
            </v-row>
            <v-row dense justify="center" v-if="quartersLoading">
              <v-col cols="12">
                  <v-progress-circular :size="15" :width="2" indeterminate></v-progress-circular>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-for="q in quarters" :key="q.quarter_id">
                <v-row align="center">
                  <v-col cols="4">
                    {{ `${q.year}, Q${q.quarter} ${q.status_id ? getCurrentStatusName(q.status_id) : '(Not set)'}` }}
                  </v-col>
                  <v-col>
                    <v-select
                      outlined
                      dense
                      hide-details
                      placeholder="Select Action"
                      :items="quarterActions"
                      item-text="name"
                      item-value="value"
                      v-model="q.action"
                    />
                  </v-col>
                  <v-col v-if="q.action === 1">
                    <v-select
                      outlined
                      dense
                      hide-details
                      placeholder="Select Status"
                      :items="quarterStatuses"
                      :disabled="q.action !== 1"
                      item-text="text"
                      item-value="value"
                      v-model="q.status_id"
                    />
                  </v-col>

<!--                  {{ q }}-->
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div v-else-if="isChangingStatusOnly">
            <v-row dense>
              <v-col>
                <v-alert
                  dense
                  type="info"
                >
                  Choose which quarters you want to move to {{ getCurrentStatusName(this.pseudoStatusToQuarterStatus[this.moveBackTo]) }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row dense justify="center" v-if="quartersLoading">
              <v-col cols="12">
                  <v-progress-circular :size="15" :width="2" indeterminate></v-progress-circular>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6" v-for="q in quarters" :key="q.quarter_id">
                <v-checkbox v-model="quartersToMove" :value="q.quarter_id">
                  <template #label>
                    <v-card outlined style="width: 100%">
                      <v-card-title>
                        {{ q.year}}
                      </v-card-title>
                      <v-card-subtitle>
                        Q{{ q.quarter }}
                      </v-card-subtitle>
                      <v-card-text>
                        <v-row dense justify="space-between">
                          <v-col>Status:</v-col>
                          <v-col cols="auto">
                            <v-chip :color="getStatusColor(q.status_id)">
                              {{ getCurrentStatusName(q.status_id) ?? 'NOT SET' }}
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </template>

                </v-checkbox>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="d-flex justify-lg-space-between" style="width: 100%">
          <v-btn text @click="$emit('update:visible', false)">Cancel</v-btn>
          <v-btn @click="handleSubmit" :disabled="!moveBackTo || !isSubmitEnabled" :loading="loading" color="primary">Submit</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>