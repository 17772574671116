<script>
import AdoptionsService from "@/services/adoptions.service";
import AuthService from "@/services/auth.service";
import ClientsService from "@/services/clients.service";
import { func as $func } from "@/globalFunctions";

export default {
  name: "UnresponsiveClientsThirdAttempt",
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    loading: false,
    status: 9,
    selectedClients: [],
    clients: [],
    currentUser: '',
    moveToDocumentVerificationLoading: false,
    sendEmailLoading: false,
    emailTemplate: undefined,
    headers: [
      {
        text: 'Client',
        value: 'client',
        filterable: true
      },
      {
        text: 'EIN',
        value: 'ein',
        filterable: true
      },
      {
        text: 'Legal Name',
        value: 'legal_name',
        filterable: true
      },
      {
        text: 'Date contact initiated',
        value: 'date',
      }
    ],

  }),
  methods: {
    async loadEmailTemplate() {
      const res = await ClientsService.getIntroductionEmailTemplate(this.entityId)

      this.emailTemplate = res.unresponsive_third

      if (!this.emailTemplate) {
        await $func.popMessage('No email template found', 'error')
      }
    },
    async sendEmails() {
      try {
        this.sendEmailLoading = true
        for (const client of this.clients) {
          const payload = {
            type: 4,
            body: this.emailTemplate,
          };

          await ClientsService.sendAdoptionEmail(client.id, payload);
        }
        selected.value = []
        $func.popMessage('Emails sent successfully!', 'success')
        await getData()
      } finally {
        this.sendEmailLoading = false
      }
    },
    async moveToDocumentVerification() {
      if (!this.isAdmin) return

      this.moveToDocumentVerificationLoading = true;
      const payload = {
        status: 6
      }

      for (let i = 0; i < this.selectedClients.length; i++) {
        let client = this.selectedClients[i]
        await AdoptionsService.patchAdoption(client.adoption_id, payload)
      }

      this.$store.dispatch('snackbar/showSnackbarMessage',
        {message: 'Moved to document verification', duration: 6000})
      this.moveToDocumentVerificationLoading = false;
      this.selectedClients = []
      await this.getData()
    },
    handleRowClick(item) {
      this.$router.push(
        {
          path: `/clients/${ item.id }/emails`,
          query: { adopterId: this.entityId },
        },
      );
    },
    async getData() {
      this.clients = await AdoptionsService.getAdoptionStatus(this.entityId, this.status)
    }
  },
  computed: {
    isAdmin() {
      return this.currentUser?.role_id === 1
    }
  },
  async mounted() {
    this.loading = true

    try {
      await this.getData()
      const data = await AuthService.getCurrentUserFromCookie()
      this.currentUser = data.data
      if (this.isAdmin)
        await this.loadEmailTemplate()
    } finally {
      this.loading = false
    }
  }
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-title>
            <v-btn v-if="isAdmin" color="warning" :disabled="!emailTemplate || !clients.length" :loading="sendEmailLoading" @click="sendEmails">Send to All</v-btn>
          </v-card-title>
          <v-card-text>
            <p>Click on a client’s row to visit their page and resend their introduction email.</p>
            <v-data-table
              :headers="headers"
              :items="clients"
              :show-select="isAdmin"
              @click:row="handleRowClick"
              v-model="selectedClients"
              :loading="loading"
              disable-pagination
              hide-default-footer
            />
          </v-card-text>
<!--          <v-card-actions>-->
<!--            <div class="d-flex justify-center" style="width: 100%">-->
<!--              <v-btn color="primary" @click="ResendIntroLetter" :disabled="!selectedClients.length">Resend Introduction letter</v-btn>-->
<!--            </div>-->
<!--          </v-card-actions>-->
          <v-card-actions>
            <div class="d-flex justify-center" style="width: 100%; gap: 10px">
              <v-btn v-if="isAdmin" :loading="moveToDocumentVerificationLoading" @click="moveToDocumentVerification" :disabled="!selectedClients.length" color="primary">Move to Document Verification</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>