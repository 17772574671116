<script>
import AdoptionsService from "@/services/adoptions.service";
import MoveBackDialog from "@/components/SinglePostAdoptFirm/MoveBackDialog.vue";

export default {
  name: "FeesReceived",
  components: { MoveBackDialog },
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    loading: false,
    moveBackDialogVisible: false,
    clients: [],
    selectedClients: [],
    headers: [
      {
        text: 'Client',
        value: 'trade_name',
        filterable: true
      },
      {
        text: 'EIN',
        value: 'ein',
        filterable: true
      },
      {
        text: 'Legal Name',
        value: 'legal_name',
        filterable: true
      }
    ],
    selectedQuarter: 1,
    status: 6,
    search: '',
  }),
  methods: {
    async getData() {
      this.loading = true

      try {
        this.clients = await AdoptionsService.getAdoptionsQuarter(this.entityId, this.selectedQuarter, this.status)
      } finally {
        this.loading = false
      }
    },
    handleRowClick(item) {
      this.$router.push(`/clients/${item.client_id}`)
    }
  },
  watch: {
    selectedQuarter() {
      this.getData()
    }
  },
  mounted() {
    this.getData()
  }
};
</script>

<template>
  <div>
    <MoveBackDialog
      v-if="moveBackDialogVisible"
      @update:visible="event => moveBackDialogVisible = event"
      @success="getData"
      :visible="moveBackDialogVisible"
      :status="20"
      :statusName="`Fees Paid`"
      :clients="selectedClients"
    />
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-title style="width: 100%">
            <v-row align="center">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-radio-group v-model="selectedQuarter" row >
                  <v-radio :value="1" label="Q1" />
                  <v-spacer />
                  <v-radio :value="2" label="Q2"/>
                  <v-spacer />
                  <v-radio :value="3" label="Q3"/>
                  <v-spacer />
                  <v-radio :value="4" label="Q4"/>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn block color="primary" :disabled="!selectedClients.length" @click="moveBackDialogVisible = true">
                  Move Back
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="clients"
              :loading="loading"
              :search="search"
              @click:row="handleRowClick"
              disable-pagination
              hide-default-footer
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>