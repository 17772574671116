<script>
import AdoptionsService from "@/services/adoptions.service";
import ClientsService from "@/services/clients.service";
import MoveBackDialog from "@/components/SinglePostAdoptFirm/MoveBackDialog.vue";
import MoveQuartersDialog from "@/components/SinglePostAdoptFirm/MoveQuartersDialog.vue";

export default {
  name: "AwaitingIRSPayments",
  components: {MoveQuartersDialog, MoveBackDialog},
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    loading: false,
    moveReceived: false,
    quartersLoading: false,
    moveBackDialogVisible: false,
    clients: [],
    selectedClients: [],
    headers: [
      {
        text: 'Client',
        value: 'trade_name',
        filterable: true
      },
      {
        text: 'EIN',
        value: 'ein',
        filterable: true
      },
      {
        text: 'Legal Name',
        value: 'legal_name',
        filterable: true
      },
    ],
    selectedQuarter: 1,
    status: 1,
    search: '',
    moveLoading: false,
    quarters: [],
  }),
  methods: {
    async getClientQuarters() {
      if (this.quarters.length) return
      this.quartersLoading = true

      try {
        this.quarters = await ClientsService.getAdoptionQuarters(this.selectedClients[0].client_id ?? this.selectedClients[0].id)
      } finally {
        this.quartersLoading = false
      }
    },
    async openDialogReceived() {
      await this.getClientQuarters()
      this.moveReceived = true
    },

    async getData() {
      this.loading = true

      try {
        this.clients = await AdoptionsService.getAdoptionsQuarter(this.entityId, this.selectedQuarter, this.status)
      } finally {
        this.loading = false
      }
    },

  },
  watch: {
    selectedQuarter(newVal) {
      this.getData()
    },
    moveReceived(newVal) {
      if (!newVal) {
        this.quarters = []
        this.getData()
      }
    }
  },
  async mounted() {
    await this.getData()
  }
};
</script>

<template>
  <div>
    <MoveBackDialog
        v-if="moveBackDialogVisible"
        @update:visible="event => moveBackDialogVisible = event"
        @success="getData"
        :visible="moveBackDialogVisible"
        :status="16"
        :statusName="`IRS Processing Claim`"
        :clients="selectedClients"
    />
    <MoveQuartersDialog
        @update:visible="event => moveReceived = event"
        @success="getData"
        :visible="moveReceived"
        :clients="selectedClients"
        :quarters="quarters"
        :move-received="moveReceived"/>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-title style="width: 100%">
            <v-row align="center">
              <v-col cols="12" md="4">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-radio-group v-model="selectedQuarter" row>
                  <v-radio :value="1" label="Q1"/>
                  <v-spacer/>
                  <v-radio :value="2" label="Q2"/>
                  <v-spacer/>
                  <v-radio :value="3" label="Q3"/>
                  <v-spacer/>
                  <v-radio :value="4" label="Q4"/>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn block color="primary" :disabled="!selectedClients.length" @click="moveBackDialogVisible = true">
                  Move Back
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
                :headers="headers"
                :items="clients"
                :search="search"
                :loading="loading"
                show-select
                v-model="selectedClients"
                item-key="client_id"
                disable-pagination
                hide-default-footer
            />
          </v-card-text>
          <v-card-actions>
            <v-btn @click="openDialogReceived" :loading="moveLoading" :disabled="!selectedClients.length"
                   color="primary">Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>
