<script>
import AdoptionsService from "@/services/adoptions.service";
import AuthService from "@/services/auth.service";
import { func as $func } from "@/globalFunctions";
import ClientsService from "@/services/clients.service";

export default {
  name: "UnresponsiveClients",
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    loading: false,
    confirmLoading: false,
    status: 7,
    clients: [],
    currentUser: '',
    moveToDocumentVerificationLoading: false,
    sendEmailLoading: false,
    emailTemplate: undefined,
    headers: [
      {
        text: 'Client',
        value: 'client',
        filterable: true
      },
      {
        text: 'EIN',
        value: 'ein',
        filterable: true
      },
      {
        text: 'Legal Name',
        value: 'legal_name',
        filterable: true
      },
      {
        text: 'Date contact initiated',
        value: 'date',
      }
    ],
    selectedClients: []
  }),
  methods: {
    async loadEmailTemplate() {
      const res = await ClientsService.getIntroductionEmailTemplate(this.entityId)

      this.emailTemplate = res.unresponsive_first

      if (!this.emailTemplate) {
        await $func.popMessage('No email template found', 'error')
      }
    },
    async sendEmails() {
      try {
        this.sendEmailLoading = true
        
        for (const client of this.clients) {
          const payload = {
            type: 2,
            body: this.emailTemplate,
          };

          await ClientsService.sendAdoptionEmail(client.id, payload);
        }

        selected.value = []
        $func.popMessage('Emails sent successfully!', 'success')
        await getData()
      } finally {
        this.sendEmailLoading = false
      }
    },
    async moveToDocumentVerification() {
      if (!this.isAdmin) return

      this.moveToDocumentVerificationLoading = true;
      const payload = {
        status: 6
      }

      for (let i = 0; i < this.selectedClients.length; i++) {
        let client = this.selectedClients[i]
        await AdoptionsService.patchAdoption(client.adoption_id, payload)
      }

      this.$store.dispatch('snackbar/showSnackbarMessage',
        {message: 'Moved to document verification', duration: 6000})
      this.moveToDocumentVerificationLoading = false;
      this.selectedClients = []
      await this.getData()
    },
    async confirmDocuments() {
      this.confirmLoading = true;

      const payload = {
        status: 6
      }

     try {
       for (let i = 0; i < this.selectedClients.length; i++) {
         let client = this.selectedClients[i]
         await AdoptionsService.patchAdoption(client.adoption_id, payload)
       }
       this.getData()
     } finally {
        this.confirmLoading = false
     }

      this.$store.dispatch('snackbar/showSnackbarMessage',
        {message: 'Verification completed.Client Moved To Document Verification.', duration: 6000})
      this.selectedClients = []
    },
    async getData() {
      this.clients = await AdoptionsService.getAdoptionStatus(this.entityId, this.status)
    }
  },
  computed: {
    isAdmin() {
      return this.currentUser?.role_id === 1
    }
  },
   async mounted() {
    this.loading = true

     try {
       await this.getData()
       const data = await AuthService.getCurrentUserFromCookie()
       this.currentUser = data.data
       if (this.isAdmin)
        await this.loadEmailTemplate()
      } finally {
        this.loading = false
      }
   }
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-title>
            <v-btn v-if="isAdmin" color="warning" :disabled="!emailTemplate || !clients.length" :loading="sendEmailLoading" @click="sendEmails">Send to All</v-btn>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="clients"
              show-select
              v-model="selectedClients"
              disable-pagination
              hide-default-footer
              :loading="loading"
            />
          </v-card-text>
          <v-card-actions>
            <div class="d-flex justify-center" style="width: 100%; gap: 10px">
              <v-btn v-if="isAdmin" :loading="moveToDocumentVerificationLoading" @click="moveToDocumentVerification" :disabled="!selectedClients.length" color="primary">Move to Document Verification</v-btn>
              <v-btn color="primary" :loading="confirmLoading" @click="confirmDocuments" :disabled="!selectedClients.length">Contact confirmed</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>
