<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "MoveQuartersDialog",
  data: () => ({
    quartersToMove: [],
    quartersLoading: false,
    activeDropdown: false,
    loading: false,
    quarterStatuses: [
      {
        value: 1,
        text: 'IRS Processing Claim'
      },
      {
        value: 2,
        text: 'Quarter Resubmission Required'
      },
      {
        value: 3,
        text: 'Quarter Denied / Appealed'
      },
      {
        value: 4,
        text: 'N/A'
      },
      {
        value: 5,
        text: 'IRS Released Payment'
      }
    ],
  }),
  props: {
    moveReceived: {
      type: Boolean,
      required: true
    },
    clients: {
      type: Array,
      required: true
    },
    quarters: {
      type: Array,
      required: true
    },
  },
  methods:{
    async handleSubmit() {
      this.loading = true

      try {
        const promises = this.quartersToMove.map(quarter =>
            ClientsService.patchAdoptionQuarters(quarter.quarter_id, {status: quarter.status})
        );
        await Promise.all(promises);
        this.quartersToMove = [];
        this.$emit('update:visible', false);
      } catch (error) {
        console.error("Error updating quarters:", error);
      } finally {
        this.loading = false;
      }
    },
    getStatusColor(statusValue) {
      const statusColors = {
        1: 'yellow',      // Awaiting IRS Payments
        2: 'orange',      // Refile Needed
        3: 'red',         // Denial Received
        4: 'gray',        // N/A
        5: 'green'        // Payment Received
      };

      return statusColors[statusValue] || 'default';
    },
    getCurrentStatusName(id){
      return this.quarterStatuses.find(status => status.value === id)?.text ?? 'NOT SET'
    },
    isQuarterSelected(quarter_id) {
      return this.quartersToMove.some(q => q.quarter_id === quarter_id);
    },
    toggleQuarter(quarter) {
      const index = this.quartersToMove.findIndex(q => q.quarter_id === quarter.quarter_id);
      console.log(quarter)
      if (index === -1) {
        this.quartersToMove.push({
          quarter_id: quarter.quarter_id,
          status: quarter.status_id
        });
      } else {
        this.quartersToMove.splice(index, 1);
      }
    },
    updateQuarterStatus(quarter_id, status) {
      const index = this.quartersToMove.findIndex(q => q.quarter_id === quarter_id);
      if (index !== -1) {
        this.quartersToMove[index].status = status;
      }
    },
    getSelectedStatus(quarter_id) {
      const quarter = this.quartersToMove.find(q => q.quarter_id === quarter_id);
      return quarter ? quarter.status : 5;
    },
    setActiveDropdown(id) {
      this.activeDropdown = id;
    },
    clearActiveDropdown() {
      setTimeout(() => {
        this.activeDropdown = null;
      }, 200);
    }
  }
}
</script>


<template>
  <v-dialog
      @click:outside="$emit('update:visible', false)"
      :value="moveReceived">
    <v-card>
      <v-card-title>
        <p>Update</p>
      </v-card-title>
      <v-card-subtitle>
        <p>{{ clients.length }} client<span v-if="clients.length > 1">s</span> will be moved in workflow.</p>
      </v-card-subtitle>
      <div
          style="border: 1px solid gray; border-radius: 10px" class="pa-4 mt-2"
      >
        <v-row dense>
          <v-col>
            <v-alert
                dense
                type="info"
            >
              Choose which quarters you want to move
            </v-alert>
          </v-col>
        </v-row>
        <v-row dense justify="center" v-if="quartersLoading">
          <v-col cols="12">
            <v-progress-circular :size="15" :width="2" indeterminate></v-progress-circular>
          </v-col>
        </v-row>
        <v-row dense style="flex-direction: row">
          <v-col cols="12" md="6" v-for="q in quarters" :key="q.quarter_id">
            <div class="d-flex">
              <v-checkbox
                  :input-value="isQuarterSelected(q.quarter_id)"
                  @change="toggleQuarter(q)"
                  class="mt-0 pt-0"
              ></v-checkbox>

              <v-card outlined style="width: 100%" class="ml-2">
                <v-card-title>
                  {{ q.year}}
                </v-card-title>
                <v-card-subtitle>
                  Q{{ q.quarter }}
                </v-card-subtitle>
                <v-card-text>
                  <v-row dense justify="space-between">
                    <v-col>Status:</v-col>
                    <v-col cols="auto">
                      <v-chip :color="getStatusColor(q.status_id)">
                        {{ getCurrentStatusName(q.status_id) }}
                      </v-chip>
                    </v-col>
                  </v-row>

                  <v-row
                      v-if="isQuarterSelected(q.quarter_id)"
                      class="mt-2"
                      dense
                  >
                    <v-col>Move to:</v-col>
                    <v-col cols="auto">
                      <div @click="setActiveDropdown(q.quarter_id)" @blur="clearActiveDropdown()">
                        <v-select
                            :items="quarterStatuses"
                            item-text="text"
                            item-value="value"
                            :value="getSelectedStatus(q.quarter_id)"
                            @change="updateQuarterStatus(q.quarter_id, $event)"
                            dense
                            outlined
                            hide-details
                            class="status-select"
                            style="width: 180px;"
                        ></v-select>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <v-card-actions>
          <div class="d-flex justify-lg-space-between" style="width: 100%">
            <v-btn text @click="$emit('update:visible', false)">Cancel</v-btn>
            <v-btn @click="handleSubmit" :disabled="!quartersToMove.length" :loading="loading" color="primary">Submit</v-btn>
          </div>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>


<style scoped>

</style>
