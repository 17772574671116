<script>
import AdoptionsService from "@/services/adoptions.service";
import ClientsService from "@/services/clients.service";
import MoveBackDialog from "@/components/SinglePostAdoptFirm/MoveBackDialog.vue";
import MoveQuartersDialog from "@/components/SinglePostAdoptFirm/MoveQuartersDialog.vue";

export default {
  name: "RefileNeeded",
  components: {MoveQuartersDialog, MoveBackDialog },
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    loading: false,
    moveBackDialogVisible: false,
    moveReceived: false,
    moveLoading: false,
    moveToDenialLoading: false,
    moveToAwaitingLoading: false,
    clients: [],
    quarters: [],
    headers: [
      {
        text: 'Client',
        value: 'trade_name',
        filterable: true
      },
      {
        text: 'EIN',
        value: 'ein',
        filterable: true
      },
      {
        text: 'Legal Name',
        value: 'legal_name',
        filterable: true
      }
    ],
    selectedClients: [],
    selectedQuarter: 1,
    status: 2,
    search: '',
  }),
  methods: {
    async openDialogReceived() {
      await this.getClientQuarters()
      this.moveReceived = true
    },
    async getClientQuarters() {
      if (this.quarters.length) return
      this.quartersLoading = true

      try {
        this.quarters = await ClientsService.getAdoptionQuarters(this.selectedClients[0].client_id ?? this.selectedClients[0].id)
      } finally {
        this.quartersLoading = false
      }
    },
    async getData() {
      this.loading = true

      try {
        this.clients = await AdoptionsService.getAdoptionsQuarter(this.entityId, this.selectedQuarter, this.status)
      } finally {
        this.loading = false
      }
    },
    async moveToAwaiting() {
      const payload = {
        status: 1
      }
      this.moveToAwaitingLoading = true

      try {
        for (let i = 0; i < this.selectedClients.length; i++) {
          let client = this.selectedClients[i]
          await ClientsService.patchAdoptionQuarters(client.quarter_id, payload)
        }
        this.selectedClients = []
        await this.getData()
      } finally {
        this.moveToAwaitingLoading = false
      }
    },
    async moveToDenialReceived() {
      const payload = {
        status: 3
      }
      this.moveToDenialLoading = true

      try {
        for (let i = 0; i < this.selectedClients.length; i++) {
          let client = this.selectedClients[i]
          await ClientsService.patchAdoptionQuarters(client.quarter_id, payload)
        }

        this.selectedClients = []
        await this.getData()
      } finally {
        this.moveToDenialLoading = false
      }
    },

  },
  watch: {
    selectedQuarter() {
      this.getData()
    },
    moveReceived(newVal) {
      if (!newVal) {
        this.quarters = []
        this.getData()
      }
    }
  },
  mounted() {
    this.getData()
  }
};
</script>

<template>
  <div>
    <MoveBackDialog
      v-if="moveBackDialogVisible"
      @update:visible="event => moveBackDialogVisible = event"
      @success="getData"
      :visible="moveBackDialogVisible"
      :status="17"
      :statusName="`Quarter Re-submission Required`"
      :clients="selectedClients"
    />
    <MoveQuartersDialog
        @update:visible="event => moveReceived = event"
        @success="getData"
        :visible="moveReceived"
        :clients="selectedClients"
        :quarters="quarters"
        :move-received="moveReceived"/>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-title style="width: 100%">
            <v-row align="center">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-radio-group v-model="selectedQuarter" row >
                  <v-radio :value="1" label="Q1" />
                  <v-spacer />
                  <v-radio :value="2" label="Q2"/>
                  <v-spacer />
                  <v-radio :value="3" label="Q3"/>
                  <v-spacer />
                  <v-radio :value="4" label="Q4"/>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn block color="primary" :disabled="!selectedClients.length" @click="moveBackDialogVisible = true">
                  Move Back
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="clients"
              :search="search"
              show-select
              :loading="loading"
              item-key="quarter_id"
              v-model="selectedClients"
              disable-pagination
              hide-default-footer
            />
          </v-card-text>
          <v-card-actions>
            <v-row justify="space-between">
              <v-col cols="auto">
                <v-btn @click="openDialogReceived" :loading="moveLoading" :disabled="!selectedClients.length"
                       color="primary">Update
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>
